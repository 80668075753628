/* eslint-disable import/max-dependencies */
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { useGetMissingRequirementsForWorkplaces } from "@src/appV2/Accounts/Documents/api/useGetMissingRequirements";
import { logEvent } from "@src/appV2/lib/analytics";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib/analytics/events";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { parseISO } from "date-fns";
import { Virtuoso } from "react-virtuoso";

import { getMissingAndPendingDocumentsForQualification } from "../../Shift/Bookability/Documents/getMissingAndPendingDocumentsForQualification";
import { convertOpenShiftToShiftWithType } from "../../Shift/convertOpenShiftToShiftWithType";
import { OpenShiftCard } from "../../Shift/Open/Card";
import { ShiftListDateHeader } from "../../Shift/Open/ListDateHeader";
import { ShiftListFooter } from "../../Shift/Open/ListFooter";
import { ShiftListItemWrapper } from "../../Shift/Open/ListItemWrapper";
import { OpenShiftsListLoadingState } from "../../Shift/Open/ListLoadingState";
import type { OpenShift, OpenShiftWorkplace } from "../../Shift/Open/types";
import { useVirtualShiftListContext } from "../../Shift/Open/useVirtualShiftListContext/useVirtualShiftListContext";
import { formatDayOfMonthWithDayName } from "../../utils/formatDayOfMonthWithDayName";
import { SHIFT_DISCOVERY_SHIFT_MODAL_PATH } from "../paths";
import { useShiftModalsDataContext } from "../useShiftModalsDataContext";
import { UrgentShiftsEmptyState } from "./EmptyState";

interface UrgentShiftsListProps {
  isEmpty?: boolean;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  workplacesMap: Map<string, OpenShiftWorkplace>;
  scrollRef: React.RefObject<HTMLDivElement>;
}

export function UrgentShiftsList(props: UrgentShiftsListProps) {
  const { isEmpty, isError, isLoading, isSuccess, workplacesMap, scrollRef } = props;

  const { listRef, virtualShiftListItems, onGroupHeaderTimeSlotClick } =
    useVirtualShiftListContext();

  const { navigateToModal, setInitialShiftData } = useShiftModalsDataContext();
  const { userId: workerId, licensesData } = useDefinedWorker();

  const missingRequirements = useGetMissingRequirementsForWorkplaces(
    workerId,
    Array.from(workplacesMap.values(), (workplace) => ({
      id: workplace.id,
      type: workplace.attributes.type,
      msa: workplace.attributes.location.msa,
      state: workplace.attributes.location.state,
      region: workplace.attributes.location.region,
    })),
    licensesData?.map((l) => l.qualification),
    {
      enabled: !isEmpty,
    }
  );

  if (isError) {
    return <Text align="center">Error loading urgent shifts</Text>;
  }

  if (isLoading) {
    return (
      <OpenShiftsListLoadingState
        texts={[
          "Finding you the best-paying urgent shifts",
          "Analyzing shift opportunities just for you",
          "Your perfect shift is just a moment away",
          "Crunching the numbers to maximize your earnings",
          "Digging deep into facility schedules",
          "Searching high and low for the best shifts",
          "Unlocking your next great opportunity",
        ]}
      />
    );
  }

  if (isSuccess && isEmpty) {
    return <UrgentShiftsEmptyState />;
  }

  return (
    <Virtuoso
      ref={listRef}
      data={virtualShiftListItems}
      customScrollParent={scrollRef.current ?? undefined}
      width="100%"
      increaseViewportBy={300}
      topItemCount={0}
      components={{
        Item: ShiftListItemWrapper,
        Footer: ShiftListFooter,
      }}
      itemContent={(index, item) => {
        if (item.type === "group-header") {
          const date = parseISO(item.dateIso);
          const label = formatDayOfMonthWithDayName(date);
          return (
            <ShiftListDateHeader
              label={label}
              totalShiftsCount={item.totalShiftsCount}
              shiftsByTimeSlotBreakdown={item.shiftsByTimeSlotBreakdown}
              timeSlotFilters={item.visibleTimeSlots}
              showDivider={index !== 0}
              onTimeSlotClick={(timeSlot) => {
                logEvent(APP_V2_USER_EVENTS.SHIFT_LIST_TIME_SLOT_CLICKED, {
                  trigger: "UrgentShifts",
                  timeSlot,
                  date: label,
                  timeSlotFilters: item.visibleTimeSlots,
                  shiftsByTimeSlotBreakdown: item.shiftsByTimeSlotBreakdown,
                });

                onGroupHeaderTimeSlotClick(item, timeSlot);
              }}
            />
          );
        }

        const shift = item as OpenShift;

        const workplace = workplacesMap.get(shift.relationships.workplace.data.id);
        if (!isDefined(workplace)) {
          return null;
        }

        const { missingDocuments, pendingDocuments } =
          getMissingAndPendingDocumentsForQualification(
            workplace.id,
            shift.attributes.qualification,
            missingRequirements.results
          );

        return (
          <OpenShiftCard
            shift={shift}
            workplace={workplace}
            missingDocuments={missingDocuments}
            pendingDocuments={pendingDocuments}
            onClick={(shift, workplace, offer) => {
              logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_OPENED, { shiftId: shift.id });
              setInitialShiftData(convertOpenShiftToShiftWithType(shift, workplace, offer));
              navigateToModal(SHIFT_DISCOVERY_SHIFT_MODAL_PATH, { shiftId: shift.id });
            }}
          />
        );
      }}
    />
  );
}
/* eslint-enable import/max-dependencies */
