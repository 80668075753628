import { convertToGeoLocation } from "@src/appV2/Location/utils";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { parseISO } from "date-fns";

import { useGetOpenShiftsForDates } from "../../Shift/Open/useGetOpenShiftsForDates";
import { getShiftDiscoveryDefaultDateRange } from "../../utils/getShiftDiscoveryDefaultDateRange";
import { DEFAULT_DISTANCE_IN_MILES_FILTER } from "../Filters/constants";
import { useShiftDiscoveryUserFiltersContext } from "../Filters/useUserFiltersContext";

interface UseUrgentShiftsDataProps {
  enabled?: boolean;
}

export function useUrgentShiftsData(props: UseUrgentShiftsDataProps = {}) {
  const { enabled = true } = props;

  const { dates: dateStrings } = useShiftDiscoveryUserFiltersContext();
  const dates = dateStrings.map((dateString) => parseISO(dateString));

  const worker = useDefinedWorker();
  const coordinates = convertToGeoLocation(worker.geoLocation?.coordinates ?? [0, 0]);

  return useGetOpenShiftsForDates({
    dates,
    dateRange: getShiftDiscoveryDefaultDateRange(),
    filter: {
      isUrgent: true,
      area: {
        latitude: coordinates.latitude,
        longitude: coordinates.longitude,
        radiusInMiles: DEFAULT_DISTANCE_IN_MILES_FILTER,
      },
    },
    enabled: enabled && worker.geoLocation?.coordinates.length === 2,
  });
}
